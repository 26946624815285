import { lazy } from 'react'
const AdminPage = lazy(()=>import('../Page/Admin'))
const LoginPage = lazy(()=>import('../Page/LoginPage'))
const NotfoundPage = lazy(() => import('../Page/NotfoundPage'))

export const DataRouterjson =[
    {
        id : 1,
        path : '/AdminPage',
        Element : <AdminPage /> 
    },
    {
        id : 2,
        path : '/AdminPage/data',
        Element : <AdminPage /> 
    },
    {
        id : 4,
        path : '/AdminPage/activity',
        Element : <AdminPage /> 
    },
    {
        id : 5,
        path : '/AdminPage/admin',
        Element : JSON.parse(window.localStorage.getItem("user") || JSON.stringify({role: "user"})).role == "admin" ? <AdminPage /> :<NotfoundPage/> 
    },
    {
        id : 6,
        path : '/',
        Element : <LoginPage /> 
    },
    {   id : 7,
        path : '/*',
        Element : <NotfoundPage />
    },
    {
        id : 8,
        path : '/AdminPage/data/addProduct',
        Element : <AdminPage /> 
    },
    {
        id : 9,
        path : '/AdminPage/stats',
        Element : <AdminPage /> 
    }
]