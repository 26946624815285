import DataRouter from './DataRooter/DataRooterJsx';
import './App.css';
import rotatePhone from "./Assets/images/rotatephone.gif";

function App() {
  return (
    <div className="container">
      <div className='WidthChange'>
        <div className="blurred-background"></div>
        <img src={rotatePhone} alt="Rotate your phone" />
        <h1>Iltimos telefoningizni aylantiring</h1>
        <h3>sozlamalarni to'g'irlashni unutmang: 🔓 🔒</h3>
      </div>
      <DataRouter />
    </div>
  );
}

export default App;
